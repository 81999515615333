import {
  getTimetablesStudent,
  deleteTimetableStudent
} from '../../API/TimetablesStudent';

const getDefaultState = () => ({ rows: {} });

const mutations = {
  SET_TIMETABLES: (state, { timetables, idStudent }) =>
    (state.rows[idStudent] = timetables),
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadTimetables(
    { commit, rootState, state },
    { idSchool, isUpdate, idStudent, type }
  ) {
    if (typeof state.rows[idStudent] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_TIMETABLES', { timetables: [], idStudent });
      }
      try {
        const timetables = await getTimetablesStudent(
          type ?? rootState.session.type,
          idSchool,
          idStudent
        );
        commit('SET_TIMETABLES', { timetables, idStudent });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteTimetable(
    { commit, rootState, getters },
    { idSchool, idTimetable, idStudent, type }
  ) {
    try {
      const res = await deleteTimetableStudent(
        type ?? rootState.session.type,
        idSchool,
        idStudent,
        idTimetable
      );
      if (res) {
        commit('SET_TIMETABLES', {
          timetables: getters
            .getTimetables(idStudent)
            .filter((f) => f.id !== idTimetable),
          idStudent
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getTimetables: (state) => (idStudent) => state.rows[idStudent] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
