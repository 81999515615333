<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <IonMenu
    side="end"
    menu-id="menu-chat-cabinets"
    class="menu-chat-cabinets"
    content-id="main"
    :swipe-gesture="false"
  >
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {{ $t('CHATS') }}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonList class="list-menu">
        <IonItem
          v-for="cabinet in cabinets"
          :key="cabinet.id"
          lines="none"
          button
          :disabled="activeCabinetId == cabinet.id"
          :detail="true"
          @click="selectCabinetChat(cabinet.id)"
        >
          <IonIcon
            v-if="activeCabinetId == cabinet.id"
            :icon="checkmarkCircleOutline"
          />
          <IonLabel>{{ cabinet.name }}</IonLabel>
          <IonBadge v-if="countNotReadedByCabinets[cabinet.id]">{{
            countNotReadedByCabinets[cabinet.id]
          }}</IonBadge>
        </IonItem>
      </IonList>
    </IonContent>
  </IonMenu>
</template>
<script>
import { openNewWindow } from '@/../vendors/appcomponents/src/helpers/openNewWindow';
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  menuController,
  IonIcon,
  IonBadge
} from '@ionic/vue';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MenusChat',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonLabel,
    IonIcon,
    IonBadge
  },
  data: () => ({
    checkmarkCircleOutline
  }),
  computed: {
    ...mapGetters('user', ['user', 'session', 'hasSession']),
    ...mapGetters('chat', [
      'cabinets',
      'activeCabinetId',
      'countNotReadedByCabinets'
    ])
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('chat', ['setCabinetChat']),
    selectCabinetChat(cabinetId) {
      this.setCabinetChat(cabinetId);
      menuController.close('menu-chat-cabinets');
    }
  }
};
</script>
<style lang="scss">
.profile-menu {
  --width: 300px;
}
.list-menu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  .margin-top {
    margin-top: auto;
  }
  padding-bottom: calc(var(--ion-safe-area-top, 0) + 10px);
}
</style>
