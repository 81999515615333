<template>
  <IonHeader>
    <IonToolbar color="primary">
      <IonTitle>{{ title }}</IonTitle>
      <IonButtons slot="start">
        <IonButton @click="$emit('hide')">
          <IonIcon slot="icon-only" :icon="closeOutline" />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <slot name="buttons" />
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  <IonContent
    :class="[
      noPadding ? '' : 'ion-padding',
      'modal-content',
      hiddenScroll ? 'hidden-scroll' : ''
    ]"
    ref="Content"
    :scroll-events="true"
    @ionScroll="onScroll"
  >
    <slot />
  </IonContent>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ModalContent',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    hiddenScroll: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide', 'onScrollEnd'],
  methods: {
    async onScroll(e) {
      const scrollElement = await this.$refs.Content.$el.getScrollElement();
      const { offsetHeight, scrollTop, scrollHeight } = scrollElement;
      if (scrollTop + offsetHeight >= scrollHeight - offsetHeight) {
        this.$emit('onScrollEnd', {
          event: e,
          scrollTop,
          scrollHeight,
          offsetHeight
        });
      }
    }
  },
  data: () => ({
    closeOutline
  })
});
</script>
<style>
.modal-content {
  height: calc(100% - 56px);
}
ion-title {
  padding-inline: 0;
}
.hidden-scroll::part(scroll) {
  overflow: hidden;
}
</style>
