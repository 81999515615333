import API from './index';

export const addAdministrator = (sessionType, idSchool, dataAdministrator) =>
  API.post(
    `/${sessionType}/school/${idSchool}/administrator`,
    dataAdministrator
  );
export const editAdministrator = (
  sessionType,
  idSchool,
  idAdministrator,
  dataAdministrator
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/administrator/${idAdministrator}?_method=PUT`,
    dataAdministrator
  );
export const getAdministrators = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/administrators`);
export const getAdministrator = (sessionType, idSchool, idAdministrator) =>
  API.get(
    `/${sessionType}/school/${idSchool}/administrator/${idAdministrator}`
  );
export const deleteAdministrator = (sessionType, idSchool, idAdministrator) =>
  API.delete(
    `/${sessionType}/school/${idSchool}/administrator/${idAdministrator}`
  );
