import API from './index';

export const addTimetable = (sessionType, idSchool, data) =>
  API.post(`/${sessionType}/school/${idSchool}/timetable`, data);
export const editTimetable = (sessionType, idSchool, idTimetable, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/timetable/${idTimetable}?_method=PUT`,
    data
  );
export const getTimetables = (sessionType, idSchool, params = {}) => {
  if (sessionType == 'user' && !idSchool) {
    return API.get(`/${sessionType}/timetables`, {
      params
    });
  } else {
    return API.get(`/${sessionType}/school/${idSchool}/timetables`, {
      params
    });
  }
};
export const getTimetable = (sessionType, idSchool, idTimetable) =>
  API.get(`/${sessionType}/school/${idSchool}/timetable/${idTimetable}`);

export const deleteTimetable = (sessionType, idSchool, idTimetable) => {
  if (sessionType == 'user') {
    return API.delete(`/${sessionType}/timetable/${idTimetable}`, {});
  } else {
    return API.delete(
      `/${sessionType}/school/${idSchool}/timetable/${idTimetable}`,
      {}
    );
  }
};
export const getTimetableStudents = (
  sessionType,
  idSchool,
  idTimetable,
  params = {}
) =>
  API.get(
    `/${sessionType}/school/${idSchool}/timetable/${idTimetable}/students`,
    { params }
  );

export const setTimetableStudents = (
  sessionType,
  idSchool,
  idTimetable,
  studentsIds,
  date = null
) =>
  API.put(
    `/${sessionType}/school/${idSchool}/timetable/${idTimetable}/students`,
    {
      students_ids: studentsIds,
      date
    }
  );

export const setTimetableDate = (
  sessionType,
  idSchool,
  idTimetable,
  date = null
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/timetable/${idTimetable}/set_date`,
    {
      date
    }
  );
