<template>
  <Modal :show="show" :loading="loading" @hide="$emit('hide')">
    <ItemInput :label="$t('NAME')" required>
      <IonInput v-model="fields.name" />
    </ItemInput>

    <ItemInput :label="$t('CONTACT-NUMBER')" required>
      <IonInput v-model="fields.phone" />
    </ItemInput>

    <ItemInput :label="$t('EMAIL')" :error="errorFields.email" required>
      <IonInput v-model="fields.email" @change="validateEmail" />
    </ItemInput>

    <ItemInput :label="$t('QUESTION')" :error="errorFields.message" required>
      <IonTextarea
        v-model="fields.message"
        :placeholder="$t('ENTER-MESSAGE-SUPPORT')"
      />
    </ItemInput>
    <IonRow>
      <IonCol
        class="ion-padding"
        v-html="
          $t('CONTACT-SUPPORT-VIA-EMAIL', {
            email: 'support@mymove.ru'
          })
        "
      >
      </IonCol>
    </IonRow>

    <template #buttons>
      <Button :label="$t('SEND')" :loading="submitting" @click="submit" />
    </template>
  </Modal>
</template>

<script>
import { IonCol, IonInput, IonRow, IonTextarea } from '@ionic/vue';
import { mapGetters } from 'vuex';

import { checkFields, clearFields } from '../../helpers/form';
import { formatPhone } from '../../helpers/formatter';
import { sendSupportMessage } from '../../API/Support';

import Modal from './Modal.vue';
import ItemInput from './items/ItemInput.vue';
import Button from './Button.vue';

export default {
  name: 'ModalSupport',
  components: {
    Modal,
    ItemInput,
    IonInput,
    IonTextarea,
    Button,
    IonRow,
    IonCol
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    phone: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  },
  emits: ['hide'],
  data: () => ({
    fields: {
      name: null,
      phone: null,
      email: null,
      message: null
    },
    requiredFields: ['email', 'message'],
    errorFields: {},
    submitting: false,
    showConfirm: true
  }),
  computed: {
    ...mapGetters('user', ['session'])
  },
  watch: {
    name: {
      immediate: true,
      handler(v) {
        this.fields.name = v;
      }
    },
    phone: {
      immediate: true,
      handler(v) {
        this.fields.phone = v;
      }
    },
    message: {
      immediate: true,
      handler(v) {
        this.fields.message = v;
      }
    }
  },
  methods: {
    formatPhone,
    clearFields,
    checkFields,

    submit() {
      if (!this.checkFields()) {
        this.submitting = true;

        const promiseSubmit = sendSupportMessage(this.fields);
        promiseSubmit
          .then(() => {
            this.$emit('hide');
            this.clearFields();
            this.fields.name = this.name;
            this.fields.phone = this.phone;
          })
          .catch((e) => {
            this.checkFields(e);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    validateEmail() {
      if (
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.fields.email)
      ) {
        this.errorFields.email = '';
      } else {
        this.errorFields.email = this.$t('ERROR-VALID-EMAIL');
      }
    }
  }
};
</script>
