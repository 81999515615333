<template>
  <IonModal
    v-bind="$attrs"
    :is-open="show"
    :can-dismiss="true"
    @didDismiss="$emit('hide')"
    @didPresent="$emit('present')"
  >
    <ModalContent
      :title="title"
      :hidden-scroll="hiddenScroll"
      :no-padding="noPadding"
      @hide="$emit('hide')"
      @onScrollEnd="$emit('onScrollEnd')"
    >
      <transition name="fade">
        <div v-if="loading" class="updating-spinner">
          <Spinner color="primary" />
        </div>
      </transition>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="error" class="error" v-html="error"></div>
      <!--eslint-enable-->
      <slot />
      <template #buttons>
        <slot name="buttons" />
        <template v-if="submit">
          <Button
            :label="submitText ? submitText : $t('SAVE')"
            :loading="submitting"
            @click="$emit('onSubmit')"
          />
        </template>
      </template>
    </ModalContent>
  </IonModal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import ModalContent from './ModalContent.vue';
import Button from './Button.vue';
import Spinner from './Spinner.vue';

export default {
  name: 'Modal',
  components: {
    ModalContent,
    IonModal,
    Spinner,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: null
    },
    submitting: {
      type: Boolean,
      default: false
    },
    hiddenScroll: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide', 'present', 'onSubmit', 'onScrollEnd'],
  data: () => ({}),
  computed: {}
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.row {
  display: flex;
  flex-direction: column;
  height: 100%;
  .row-buttons {
    background: var(--ion-toolbar-background, var(--ion-color-step-50, #fff));
    padding-top: var(--padding-bottom);
  }
}
.updating-spinner {
  justify-items: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.3);
    filter: blur(10px);
  }
  > * {
    position: absolute;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
  }
}
.updating-spinner > * {
  flex: 0 0 100%;
}
.error {
  color: var(--ion-color-danger);
  padding-left: 18px;
}
</style>
