import Flow from '@flowjs/flow.js';
// import { uuid } from 'vue-uuid';
import { toastController } from '@ionic/vue';

const getDefaultState = () => ({
  list: [],
  uploadedVideo: null,
  selectedPost: null,
  selectedPosts: []
});
const mutations = {
  ADD_VIDEO(state, payload) {
    state.list.push(payload);
  },
  SET_PROGRESS(state, payload) {
    const video = state.list.find((v) => v.postId === payload.postId);
    if (video) {
      video.progress = payload.progress;
    }
  },
  SET_COMPLETE(state, payload) {
    const video = state.list.find((v) => v.postId === payload.postId);
    if (video) {
      video.complete = payload.complete;
      state.uploadedVideo = { ...video };
    }
  },
  SET_SELECTED_POST(state, payload) {
    state.selectedPost = payload;
  },
  SET_SELECTED_POSTS(state, payload) {
    state.selectedPosts = payload;
  }
};
const actions = {
  async uploadVideo({ commit, rootState }, { videos, postId }) {
    commit('ADD_VIDEO', {
      postId,
      progress: 0,
      complete: false
    });
    const flow = new Flow({
      target: `${process.env.VUE_APP_URL_UPLOADER}video_post/${postId}/upload`,
      chunkSize: 7 * 1024 * 1024,
      headers: {
        session: rootState?.user?.session?.idSession
      }
    });

    flow.on('error', async () => {
      flow.wasError = true;
      flow.cancel();
      const toast = await toastController.create({
        color: 'danger',
        message: 'Ошибка при загрузке видео',
        duration: 2000
      });
      await toast.present();
    });
    // eslint-disable-next-line
    for (const i in videos) {
      try {
        const url = encodeURI(videos[i].url);
        // const fileName = path.split('/').pop();
        // eslint-disable-next-line
        const response = await fetch(url);
        // eslint-disable-next-line
        const blob = await response.blob();
        blob.name = videos[i].uid;

        flow.addFile(blob);
      } catch (e) {
        console.error(e);
        return;
      }
    }
    flow.upload();
    flow.on('fileSuccess', async (f, m) => {
      console.log('fileSuccess', f, m);
    });
    flow.on('complete', async () => {
      commit('SET_COMPLETE', {
        postId,
        complete: true
      });
      if (!flow.wasError) {
        const toast = await toastController.create({
          message: 'Загрузка видео успешно завершена',
          duration: 1000
        });
        await toast.present();
      }
    });
    flow.on('progress', () => {
      commit('SET_PROGRESS', {
        postId,
        progress: Math.max(flow.progress(true) - 0.1, 0)
      });
    });
  },
  setSelectedPost({ commit }, post) {
    commit('SET_SELECTED_POST', post);
  },
  setSelectedPosts({ commit }, posts) {
    commit('SET_SELECTED_POSTS', posts);
  }
};
const getters = {
  list: (state) => state.list,
  uploadedVideo: (state) => state.uploadedVideo,
  selectedPost: (state) => state.selectedPost,
  selectedPosts: (state) => state.selectedPosts
};
const state = getDefaultState;

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
