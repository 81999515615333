import { getStudentTeachers } from '../../API/Students';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_STUDENT_TEACHERS(state, { studentTeachers, idStudent }) {
    state.rows[idStudent] = studentTeachers;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadStudentTeachers(
    { commit, rootState, state },
    { idSchool, idStudent, isUpdate, type }
  ) {
    if (typeof state.rows[idStudent] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_STUDENT_TEACHERS', { studentTeachers: [], idStudent });
      }
      try {
        const studentTeachers = await getStudentTeachers(
          type ?? rootState.session.type,
          idSchool,
          idStudent
        );
        commit('SET_STUDENT_TEACHERS', { studentTeachers, idStudent });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
const getters = {
  getStudentTeachers: (state) => (idStudent) => state.rows[idStudent] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
