import {
  // getSchools,
  getSchool
} from '../../API/Schools';

const getDefaultState = () => ({ rows: {}, row: {} });
const mutations = {
  SET_SCHOOLS(state, { teachers, idSchool }) {
    state.rows[idSchool] = teachers;
  },
  SET_SCHOOL(state, { school, idSchool }) {
    state.row[idSchool] = school;
  }
};
const actions = {
  // async loadTeachers({ commit, rootState, state }, { idSchool, isUpdate }) {
  //   if (typeof state.rows[idSchool] === 'undefined' || isUpdate) {
  //     if (!isUpdate) {
  //       commit('SET_SCHOOLS', { teachers: [], idSchool });
  //     }
  //     try {
  //       const teachers = await getSchools(rootState.session.type, idSchool);
  //       commit('SET_SCHOOLS', { teachers, idSchool });
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  // },
  async loadSchool({ commit, state }, { idSchool, isUpdate }) {
    if (typeof state.row[idSchool] === 'undefined' || isUpdate) {
      try {
        const school = await getSchool('public', idSchool);
        commit('SET_SCHOOL', { school, idSchool });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
const getters = {
  getSchools: (state) => () => state.rows || [],
  getSchool: (state) => (idSchool) => state.row[idSchool] || {}
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
