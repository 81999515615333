export default {
  namespaced: true,
  mutations: {},
  getters: {
    check: (state) => (need, type) => {
      const needs = need.split('.');

      if (needs.length === 2) {
        return (
          typeof state[type] !== 'undefined' &&
          typeof state[type][needs[0]] !== 'undefined' &&
          state[type][needs[0]]?.includes(needs[1])
        );
      }
      return false;
    }
  },
  actions: {},
  state: {
    owner: {
      schools: ['add', 'edit'],
      services: ['get_list', 'edit'],
      teachers: ['get_list', 'get_item'],
      finances: ['get_list'],
      administrators: ['get_list'],
      timetable: ['add', 'edit'],
      lessons_plus: ['get_list', 'add', 'edit'],
      lessons_minus: ['get_list'],
      comment_student: ['get_list'],
      student_teachers: ['get_list'],
      timetable_student: ['get_list', 'edit'],
      timetable_students: ['get_list', 'edit'],
      students: ['delete', 'edit', 'get_item'],
      requests: ['get_list'],
      service_teachers: ['edit'],
      forms: ['get_list'],
      // Акции
      // student_bonuses: ['add'],
      videoconference: ['get_list'],
      // stock: ['get_list'],
      tickets: ['get_list'],
      student_files: ['get_list'],
      students_groups: ['get_list'],
      services_groups: ['get_list'],
      visits_students: ['get_list'],
      teachers_schedule: ['get_list']
    },
    teacher: {
      lessons_plus: ['get_list', 'add', 'edit'],
      lessons_minus: ['get_list'],
      comment_student: ['get_list'],
      worktimes: ['get_list'],
      timetable_student: ['get_list', 'edit'],
      timetable_students: ['get_list', 'edit'],
      students: ['edit', 'delete', 'get_item'],
      timetable: ['add', 'edit'],
      visits_students: ['get_list']
    },
    administrator: {
      services: ['get_list'],
      teachers: ['get_list', 'get_item'],
      timetable: ['add', 'edit'],
      lessons_plus: ['get_list', 'add', 'edit'],
      lessons_minus: ['get_list'],
      comment_student: ['get_list'],
      student_teachers: ['get_list'],
      students: ['edit', 'delete', 'get_item'],
      timetable_student: ['get_list', 'edit'],
      timetable_students: ['get_list', 'edit'],
      requests: ['get_list'],
      student_files: ['get_list'],
      students_groups: ['get_list'],
      services_groups: ['get_list'],
      visits_students: ['get_list']
    }
  }
};
