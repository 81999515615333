import { createI18n } from 'vue-i18n';
import ru from '@/locales/ru.json';
import ruAppComponents from '@/../vendors/appcomponents/src/locales/ru.json';
import en from '@/locales/en.json';
import enAppComponents from '@/../vendors/appcomponents/src/locales/en.json';

const messages = {
  ru: {
    ...ruAppComponents,
    ...ru
  },
  en: {
    ...enAppComponents,
    ...en
  }
};
export default new createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru',
  messages,
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: (choice, choicesLength) => {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    }
  }
});
