import { getStudents, getStudent, deleteStudent } from '../../API/Students';

const getDefaultState = () => ({ rows: {}, row: {} });
const mutations = {
  SET_STUDENTS(state, { students, key }) {
    state.rows[key] = students;
  },
  SET_STUDENT(state, { student, key }) {
    state.row[key] = student;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadStudents(
    { commit, rootState, state },
    { idSchool, isUpdate, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = selfType + idSchool;
    if (typeof state.rows[key] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_STUDENTS', { students: [], key });
      }
      try {
        const students = await getStudents(selfType, idSchool);
        commit('SET_STUDENTS', { students, key });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async loadStudent(
    { commit, rootState, state },
    { idSchool, idStudent, isUpdate, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = selfType + idStudent;
    if (typeof state.row[key] === 'undefined' || isUpdate) {
      try {
        const student = await getStudent(selfType, idSchool, idStudent);
        commit('SET_STUDENT', { student, key });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteStudent(
    { commit, rootState, getters },
    { idSchool, idStudent, type }
  ) {
    try {
      const res = await deleteStudent(
        type ?? rootState.session.type,
        idSchool,
        idStudent
      );
      if (res) {
        commit('SET_STUDENTS', {
          students: getters
            .getStudents(idSchool)
            .filter((s) => s.id !== idStudent),
          idSchool
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getStudents: (state) => (idSchool, type) => state.rows[type + idSchool] || [],
  getStudent: (state) => (idStudent, type) => state.row[type + idStudent] || {}
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
