import { deleteComment, getComments } from '../../API/StudentsComments';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_COMMENTS(state, { comments, idStudent }) {
    state.rows[idStudent] = comments;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadComments(
    { commit, rootState, state },
    { idSchool, idStudent, isUpdate, type }
  ) {
    if (typeof state.rows[idStudent] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_COMMENTS', { comments: [], idStudent });
      }
      try {
        const comments = await getComments(
          type ?? rootState.session.type,
          idSchool,
          idStudent
        );
        commit('SET_COMMENTS', { comments, idStudent });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteComment(
    { commit, rootState, getters },
    { idSchool, idStudent, idComment, type }
  ) {
    try {
      const res = await deleteComment(
        type ?? rootState.session.type,
        idSchool,
        idStudent,
        idComment
      );
      if (res) {
        commit('SET_COMMENTS', {
          comments: getters
            .getComments(idStudent)
            .filter((l) => l.id !== idComment),
          idStudent
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getComments: (state) => (idStudent) => state.rows[idStudent] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
