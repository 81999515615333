import {
  getAdministrators,
  getAdministrator,
  deleteAdministrator
} from '../../API/Administrators';

const getDefaultState = () => ({ rows: {}, row: {} });
const mutations = {
  SET_ADMINISTRATORS(state, { administrators, idSchool }) {
    state.rows[idSchool] = administrators;
  },
  SET_ADMINISTRATOR(state, { administrator, idAdministrator }) {
    state.row[idAdministrator] = administrator;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadAdministrators(
    { commit, rootState, state },
    { idSchool, isUpdate, type }
  ) {
    if (typeof state.rows[idSchool] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_ADMINISTRATORS', { administrators: [], idSchool });
      }
      try {
        const administrators = await getAdministrators(
          type ?? rootState.session.type,
          idSchool
        );
        commit('SET_ADMINISTRATORS', { administrators, idSchool });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async loadAdministrator(
    { commit, rootState, state },
    { idSchool, idAdministrator, isUpdate, type }
  ) {
    if (typeof state.row[idAdministrator] === 'undefined' || isUpdate) {
      try {
        const administrator = await getAdministrator(
          type ?? rootState.session.type,
          idSchool,
          idAdministrator
        );
        commit('SET_ADMINISTRATOR', { administrator, idAdministrator });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteAdministrator(
    { commit, rootState, getters },
    { idSchool, idAdministrator, type }
  ) {
    try {
      const res = await deleteAdministrator(
        type ?? rootState.session.type,
        idSchool,
        idAdministrator
      );
      if (res) {
        commit('SET_ADMINISTRATORS', {
          teachers: getters
            .getAdministrators(idSchool)
            .filter((t) => t.id !== idAdministrator),
          idSchool
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getAdministrators: (state) => (idSchool) => state.rows[idSchool] || [],
  getAdministrator: (state) => (idAdministrator) =>
    state.row[idAdministrator] || {}
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
