import API from './index';

export const addLessonPlus = (sessionType, idSchool, idStudent, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus`,
    data
  );
export const editLessonPlus = (
  sessionType,
  idSchool,
  idStudent,
  idLessonPlus,
  data
) =>
  API.put(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus/${idLessonPlus}`,
    data
  );
export const getLessonsPlus = (sessionType, idSchool, idStudent) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lessons_plus`
  );
export const getLessonPlus = (sessionType, idSchool, idStudent, idLessonPlus) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus/${idLessonPlus}`,
    {}
  );
export const deleteLessonPlus = (
  sessionType,
  idSchool,
  idStudent,
  idLessonPlus
) =>
  API.delete(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus/${idLessonPlus}`
  );

export const getLessonsPlusByStudent = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/lessons_plus`, {});
