<template>
  <div
    :class="[
      `sc-ion-input-${mode}-h`,
      `sc-ion-input-${mode}-s`,
      mode,
      'has-value'
    ]"
  >
    <input
      ref="input"
      v-maska="{
        mask: '+# (###) ###-##-### ####'
      }"
      :value="modelValue"
      :class="['native-input', `sc-ion-input-${mode}`]"
      maxlength="22"
      type="tel"
      placeholder="+_ (_) _-__-__"
      @input="(event) => $emit('update:modelValue', event.target.value)"
    />
    <IonInput v-show="false" />
  </div>
</template>
<script>
import { IonInput } from '@ionic/vue';

export default {
  name: 'InputPhone',
  components: { IonInput },

  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    mode() {
      return 'ios'; // isPlatform('ios') ? 'ios' : 'md';
    }
  }
};
</script>
