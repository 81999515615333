<template>
  <IonButtons v-if="hint">
    <IonButton @click.prevent="open">
      <IonIcon
        slot="icon-only"
        size="small"
        color="medium"
        :icon="informationOutline"
      />
    </IonButton>
    <IonPopover :is-open="isOpen" :event="event" @didDismiss="isOpen = false">
      <IonContent :scroll-y="false" trigger-action="click">
        <p class="text">{{ hint }}</p>
      </IonContent>
    </IonPopover>
  </IonButtons>
</template>

<script>
import {
  IonPopover,
  IonContent,
  IonIcon,
  IonButtons,
  IonButton
} from '@ionic/vue';
import { informationOutline } from 'ionicons/icons';

export default {
  name: 'Hint',
  components: {
    IonPopover,
    IonContent,
    IonIcon,
    IonButtons,
    IonButton
  },
  props: {
    hint: {
      type: String,
      default: null
    }
  },
  data: () => ({
    informationOutline,
    isOpen: false,
    event: null
  }),
  methods: {
    open(e) {
      this.isOpen = true;
      this.event = e;
    }
  }
};
</script>

<style scoped>
.text {
  margin: 5px 10px 5px 10px;
  padding: 0;
  font-size: 14px;
}
</style>
