import API from './index';

export const addLessonMinus = (
  sessionType,
  idSchool,
  idStudent,
  idlessonPlus,
  data
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus/${idlessonPlus}/lesson_minus`,
    data
  );
export const editLessonMinus = (
  sessionType,
  idSchool,
  idStudent,
  idLessonPlus,
  idLessonMinus,
  data
) =>
  API.put(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_plus/${idLessonPlus}/lesson_minus/${idLessonMinus}`,
    data
  );
export const getLessonsMinus = (sessionType, idSchool, idStudent) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lessons_minus`
  );
export const getLessonMinus = (
  sessionType,
  idSchool,
  idStudent,
  idlessonMinus
) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_minus/${idlessonMinus}`
  );
export const deleteLessonMinus = (
  sessionType,
  idSchool,
  idStudent,
  idlessonMinus
) =>
  API.delete(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/lesson_minus/${idlessonMinus}`
  );

export const getLessonsMinusByStudent = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/lessons_minus`);

export const studentFastLessonMinus = (
  sessionType,
  idSchool,
  idStudent,
  idLessonPlus,
  idService,
  idTimetable,
  dateVisit
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/fast_lesson_minus`,
    {
      lesson_plus_id: idLessonPlus,
      service_id: idService,
      timetable_id: idTimetable,
      date_visit: dateVisit
    }
  );
