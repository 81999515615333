import { getServices, deleteService } from '../../API/Services';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_SERVICES(state, { services, key }) {
    state.rows[key] = services;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};

const actions = {
  async loadServices(
    { commit, rootState, state },
    { idSchool, isUpdate, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = selfType + idSchool;
    if (typeof state.rows[key] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_SERVICES', { services: [], key });
      }
      try {
        const services = await getServices(selfType, idSchool);
        commit('SET_SERVICES', { services, key });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteService(
    { commit, rootState, getters },
    { idSchool, idService, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = selfType + idSchool;
    try {
      const res = await deleteService(selfType, idSchool, idService);
      if (res) {
        commit('SET_SERVICES', {
          services: getters
            .getServices(idSchool, selfType)
            .filter((s) => s.id !== idService),
          key
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getServices: (state) => (idSchool, type) => state.rows[type + idSchool] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
