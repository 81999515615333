import API from './index';

export const getSchools = (sessionType, params) =>
  API.get(`/${sessionType}/schools`, params);
export const getSchool = (sessionType, schoolId) =>
  API.get(`/${sessionType}/school/${schoolId}`);
export const addSchool = (sessionType, dataSchool) =>
  API.post(`/${sessionType}/school`, dataSchool);
export const editSchool = (sessionType, schoolId, dataSchool) =>
  API.post(`/${sessionType}/school/${schoolId}?_method=PUT`, dataSchool);
export const deleteSchool = (sessionType, idSchool) =>
  API.delete(`/${sessionType}/school/${idSchool}`);

export const getSchoolStatistic = (schoolId) =>
  API.get(`/public/school/${schoolId}/statistic`, { params: {} });
export const setSchoolSubscription = (typeSession, schoolId) =>
  API.post(`/${typeSession}/school/${schoolId}/subscription`, {});

export const getSchoolsCategories = (sessionType = 'public', city) =>
  API.get(`/${sessionType}/schools_categories`, { params: { city } });

export const getPublicSchools = ({
  page,
  category,
  categories,
  type,
  enable_affiliate_program,
  search,
  city
}) =>
  API.get(`public/schools`, {
    params: {
      page,
      category,
      categories,
      type,
      enable_affiliate_program,
      search,
      city
    }
  });

export const getSchoolsCatigories = () => API.get(`public/schools_categories`);

export const searchSchools = (data) =>
  API.get(`public/companies/live_search?search=${data}`);
