import { Globalization } from '@awesome-cordova-plugins/globalization';

export async function getClientLang() {
  const defLang = 'ru';
  try {
    const language = await Globalization.getPreferredLanguage();
    return language?.value?.split('-')[0] ?? defLang;
  } catch (e) {
    console.error(e);
  }
  return navigator?.language?.split('-')[0] ?? defLang;
}
