<template>
  <div class="spinner-wrapper">
    <IonIcon
      class="spinner-inside"
      size="middle"
      color="white"
      :icon="logo.inside"
    />

    <IonIcon
      :style="styleAround"
      :class="['spinner-around', rotate === null ? 'animation' : '']"
      size="large"
      color="white"
      :icon="logo.around"
    />
  </div>
</template>
<script>
import { IonIcon } from '@ionic/vue';
import inside from '@/assets/logo/inside.svg';
import around from '@/assets/logo/around.svg';

export default {
  name: 'Spinner',
  components: {
    IonIcon
  },

  props: {
    rotate: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    logo: {
      inside,
      around
    }
  }),
  computed: {
    styleAround() {
      const s = {};
      if (this.rotate !== null) {
        s.transform = `rotate(${this.rotate}deg)`;
      }
      return s;
    }
  }
};
</script>
<style lang="scss" scoped>
.spinner-wrapper {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  .spinner-inside {
    position: absolute;
    font-size: 18px;
    left: calc(50% - 8px);
    top: calc(50% - 9px);
  }
  .spinner-around {
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .spinner-around.animation {
    animation: loading 2s linear infinite;
    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
