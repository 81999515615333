import { AppMetrica, AppMetricaPlugin } from 'capacitor-appmetrica-plugin';

export class AnalyticsService {
  private appmetrica: AppMetricaPlugin = AppMetrica;

  async initialization() {
    try {
      await this.appmetrica.activate({
        apiKey: 'c81baae9-5600-42f8-94df-3ab6f85af70d',
        logs: true
      });
    } catch (e) {
      console.error(e);
    }
  }

  //   async logEvent(name: string, params?: Object) {
  //     await this.appmetrica.logEvent(name, params);
  //   }

  async setUserProfileID(id: string) {
    return this.appmetrica.setUserProfileId({ id });
  }
}
