import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller';

export const hideWindow = () => {
  SafariViewController.hide();
};
export const openNewWindow = (url) => {
  SafariViewController.isAvailable()
    .then((available) => {
      if (available) {
        SafariViewController.show({
          url,
          hidden: false,
          animated: true,
          transition: 'slide',
          enterReaderModeIfAvailable: true,
          tintColor: '#761cea'
        }).subscribe(
          (result) => {
            console.log(result);
            if (result.event === 'opened') console.log('Opened');
            else if (result.event === 'loaded') console.log('Loaded');
            else if (result.event === 'closed') console.log('Closed');
          },
          (error) => console.error(error)
        );
      } else {
        window.open(url, '_blank', 'location=yes');
      }
    })
    .catch(() => {
      window.open(url, '_blank', 'location=yes');
    });
};
