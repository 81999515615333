import { getChatCabinets } from '../../API/Chat';

const getDefaultState = () => ({
  countNotReaded: {},
  cabinets: [],
  activeCabinet: localStorage?.activeCabinetChatId ?? 'me'
});

const mutations = {
  SET_COUNT_NOT_READED: (state, { countNotReaded, cabinetId }) => {
    state.countNotReaded[cabinetId] = countNotReaded;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState()),
  SET_CABINETS: (state, payload) => (state.cabinets = payload),
  SET_ACTIVE_CABINET: (state, payload) => (state.activeCabinet = payload)
};
const actions = {
  setUnread({ commit }, { unread, cabinetId }) {
    commit('SET_COUNT_NOT_READED', { countNotReaded: unread, cabinetId });
  },
  setCabinetChat({ commit }, cabinetId) {
    commit('SET_ACTIVE_CABINET', cabinetId);
    localStorage.activeCabinetChatId = cabinetId;
  },
  getChatCabinets() {
    return new Promise((resolve, reject) => {
      getChatCabinets()
        .then((cabinets) => {
          this.commit('chat/SET_CABINETS', cabinets);
          resolve(cabinets);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  }
};
const getters = {
  countNotReaded: (state) => {
    return Object.keys(state.countNotReaded).length
      ? Object.values(state.countNotReaded)?.reduce((v, n) => {
          return v + n;
        })
      : 0;
  },
  countNotReadedOther: (state) => {
    const l = { ...state.countNotReaded };
    delete l[state.activeCabinet];
    return Object.values(l)?.reduce((v, n) => {
      return v + n;
    });
  },
  countNotReadedByCabinets: (state) => {
    return state.countNotReaded;
  },
  cabinets: (state) => {
    return state.cabinets;
  },
  activeCabinet: (state) => {
    const c = state.cabinets?.find((v) => v?.id == state.activeCabinet);

    return c ? c : state.cabinets[0];
  },
  activeCabinetId: (state) => {
    return state.activeCabinet;
  }
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
