import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { setUserDevice } from '@/../vendors/appcomponents/src/API/Notifications';
import { FCM } from '@capacitor-community/fcm';
import Echo from 'laravel-echo';
import mitt from 'mitt';

window.io = require('socket.io-client');

export const pushNotifications = {
  echoServer: null,
  socketPrivateChannel: null,
  $emitter: mitt(),
  OnInit(session) {
    try {
      if (Capacitor.getPlatform() !== 'web') {
        this.registerPush();
      }
      this.registerBroadcast(session);
    } catch (e) {
      console.error(e);
    }
  },
  async registerBroadcast(session) {
    this.echoServer = new Echo({
      broadcaster: 'socket.io',
      host: process.env?.VUE_APP_URL_SOCKET,
      auth: {
        headers: {
          session
        }
      }
      // transports: ['websocket', 'polling', 'flashsocket']
    });
  },
  async registerPush() {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    this.addListeners();
    await PushNotifications.register();
  },
  async addListeners() {
    PushNotifications.addListener('registrationError', (error) => {
      // eslint-disable-next-line
      console.error('Error: ' + JSON.stringify(error));
    });
  },
  addListenernReceive(done = () => {}) {
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification) => {
        done(notification);
      }
    ).catch((e) => {
      console.error(e);
    });
  },
  async addListenerSocket(done = () => {}, ticket = 'notification.new') {
    this.$emitter.on('subscribed', () => {
      this.socketPrivateChannel?.listen(`.${ticket}`, (e) => {
        done(e);
      });
    });
  },
  async addListenerActionPerformed(done = () => {}) {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification) => {
        const { data } = notification.notification;
        done(data);
      }
    ).catch((e) => {
      console.error(e);
    });
  },
  async subscribeTo(userId) {
    const topic = `user${userId}`;
    console.log(`try subscribe to topic “${topic}”`);
    FCM.subscribeTo({ topic })
      .then(() => console.log(`subscribed to topic “${topic}”`))
      .catch((err) => console.error(err));

    FCM.getToken()
      .then(async (data) => {
        setUserDevice(data.token, Capacitor.getPlatform());
      })
      .catch((err) => console.error(err));
    this.socketPrivateChannel = this.echoServer.private(
      `notification.user.${userId}`
    );
    this.$emitter.emit('subscribed');
  },
  async unsubscribeFrom(userId) {
    const topic = `user${userId}`;
    FCM.unsubscribeFrom({ topic })
      .then(() => console.log(`unsubscribe from topic “${topic}”`))
      .catch((err) => console.error(err));
  },
  async setReadedAllNotifications() {
    PushNotifications.removeAllDeliveredNotifications();
  },
  async registerTinodeFCM(tinode) {
    FCM.getToken()
      .then(async (data) => {
        tinode.setDeviceToken(data.token);
      })
      .catch((err) => console.error(err));
  }
};
