import user from './modules/user';
import notifications from './modules/notifications';
import chat from './modules/chat';
import teachers from './modules/teachers';
import students from './modules/students';
import lessonsPlus from './modules/lessonsPlus';
import lessonsMinus from './modules/lessonsMinus';
import services from './modules/services';
import banners from './modules/banners';
import studentTeachers from './modules/studentTeachers';
import finances from './modules/finances';
import workTimes from './modules/workTimes';
import commentsStudent from './modules/commentsStudent';
import timetables from './modules/timetables';
import timetablesStudent from './modules/timetablesStudent';
import timetablesTeacher from './modules/timetablesTeacher';
import administrators from './modules/administrators';
import schools from './modules/schools';
import videoPosts from './modules/videoPosts';

export const modules = {
  user,
  notifications,
  teachers,
  students,
  lessonsPlus,
  lessonsMinus,
  services,
  studentTeachers,
  finances,
  workTimes,
  commentsStudent,
  timetables,
  timetablesStudent,
  timetablesTeacher,
  administrators,
  schools,
  videoPosts,
  chat,
  banners
};
