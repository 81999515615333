import { getLessonsMinus, deleteLessonMinus } from '../../API/LessonMinus';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_LESSONS_MINUS(state, { lessonsMinus, idStudent }) {
    state.rows[idStudent] = lessonsMinus;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadLessonsMinus(
    { commit, rootState, state },
    { idSchool, idStudent, isUpdate, type }
  ) {
    if (typeof state.rows[idStudent] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_LESSONS_MINUS', { lessonsMinus: [], idStudent });
      }
      try {
        const lessonsMinus = await getLessonsMinus(
          type ?? rootState.session.type,
          idSchool,
          idStudent
        );
        commit('SET_LESSONS_MINUS', { lessonsMinus, idStudent });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteLessonMinus(
    { commit, rootState, getters },
    { idSchool, idStudent, idLessonMinus, type }
  ) {
    try {
      const res = await deleteLessonMinus(
        type ?? rootState.session.type,
        idSchool,
        idStudent,
        idLessonMinus
      );
      if (res) {
        commit('SET_LESSONS_MINUS', {
          lessonsMinus: getters
            .getLessonsMinus(idStudent)
            .filter((l) => l.id !== idLessonMinus),
          idStudent
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getLessonsMinus: (state) => (idStudent) => state.rows[idStudent] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
