import API from './index';

export const addTimetableTeacher = (sessionType, idSchool, idTeacher, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/timetable`,
    data
  );
export const editTimetableTeacher = (
  sessionType,
  idSchool,
  idTeacher,
  idTimetable,
  data
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/timetable/${idTimetable}?_method=PUT`,
    data
  );
export const getTimetableTeacher = (
  sessionType,
  idSchool,
  idTeacher,
  idTimetable
) =>
  API.get(
    `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/timetable/${idTimetable}`
  );

export const getTimetablesTeacher = (sessionType, idSchool, idTeacher) =>
  API.get(`/${sessionType}/school/${idSchool}/teacher/${idTeacher}/timetables`);
