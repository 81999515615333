import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import * as ROUTES from '@/../vendors/appcomponents/src/router/routes';
import * as PATHS from '@/../vendors/appcomponents/src/router/constants';
import DEF from '@/../vendors/appcomponents/src/router/default';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => {},
    beforeEnter(to: any, from: any): string | boolean {
      return window.$store.getters['user/hasSession']
        ? ROUTES.routeMembership().path
        : ROUTES.routeSignIn().path;
    }
  },
  {
    path: '/',
    component: () => import('@/layouts/LayoutHome.vue'),
    // beforeEnter(to: any, from: any): string | boolean {
    //   return !window.$store.getters.hasSession ? PATHS.PATH_SIGN_IN_TYPES : true;
    // },
    children: [...DEF]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const DEFAULT_TITLE = 'MyMove - онлайн абонемент';

router.beforeEach((to, from, next) => {
  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  } else {
    document.title = DEFAULT_TITLE;
  }
  next();
});

export default router;
