import { getFinances, deleteFinance } from '../../API/Finances';

const getDefaultState = () => ({ rows: {} });

const mutations = {
  SET_FINFANCES: (state, { finances, idSchool }) =>
    (state.rows[idSchool] = finances),
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadFinances(
    { commit, rootState, state },
    { idSchool, dateFrom, dateTo, isUpdate, type }
  ) {
    if (typeof state.rows[idSchool] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_FINFANCES', { finances: [], idSchool });
      }
      try {
        const finances = await getFinances(
          type ?? rootState.session.type,
          idSchool,
          dateFrom,
          dateTo
        );
        commit('SET_FINFANCES', { finances, idSchool });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteFinance(
    { commit, rootState, getters },
    { idSchool, idFinance, type }
  ) {
    try {
      const res = await deleteFinance(
        type ?? rootState.session.type,
        idSchool,
        idFinance
      );
      if (res) {
        commit('SET_FINFANCES', {
          finances: getters
            .getFinances(idSchool)
            .filter((f) => f.id !== idFinance),
          idSchool
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getFinances: (state) => (idSchool) => state.rows[idSchool] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
