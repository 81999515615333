import { getTeachers, getTeacher, deleteTeacher } from '../../API/Teachers';

const getDefaultState = () => ({ rows: {}, row: {} });
const mutations = {
  SET_TEACHERS(state, { teachers, idSchool }) {
    state.rows[idSchool] = teachers;
  },
  SET_TEACHER(state, { teacher, idTeacher }) {
    state.row[idTeacher] = teacher;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadTeachers(
    { commit, rootState, state },
    { idSchool, isUpdate, type }
  ) {
    if (typeof state.rows[idSchool] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_TEACHERS', { teachers: [], idSchool });
      }
      try {
        const teachers = await getTeachers(
          type ?? rootState.session.type,
          idSchool
        );
        commit('SET_TEACHERS', { teachers, idSchool });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async loadTeacher(
    { commit, rootState, state },
    { idSchool, idTeacher, isUpdate, type }
  ) {
    if (typeof state.row[idTeacher] === 'undefined' || isUpdate) {
      try {
        const teacher = await getTeacher(
          type ?? rootState.session.type,
          idSchool,
          idTeacher
        );
        commit('SET_TEACHER', { teacher, idTeacher });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteTeacher(
    { commit, rootState, getters },
    { idSchool, idTeacher, type }
  ) {
    try {
      const res = await deleteTeacher(
        type ?? rootState.session.type,
        idSchool,
        idTeacher
      );
      if (res) {
        commit('SET_TEACHERS', {
          teachers: getters
            .getTeachers(idSchool)
            .filter((t) => t.id !== idTeacher),
          idSchool
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getTeachers: (state) => (idSchool) => state.rows[idSchool] || [],
  getTeacher: (state) => (idTeacher) => state.row[idTeacher] || {}
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
