import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import { showToast } from './helpers/toast';
import { showNumber } from './helpers/numeral';
import { getThumbUrl } from './helpers/image';
import { createIntl } from 'vue-intl';
// зависимость чата
import * as ru from '../../webchat/src/i18n.min/ru.json';
import * as ROUTES from './router/routes';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-ca';
import 'material-icons/iconfont/material-icons.css';
import Maska from 'maska';
import { IonicVue, IonIcon } from '@ionic/vue';
import YmapPlugin from 'vue-yandex-maps';
import i18n from '@/helpers/i18n';
import mitt from 'mitt';

import App from './App.vue';
import router from '../../../src/router';
import store from '../../../src/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

declare global {
  interface Window {
    $store: any;
  }
}

const settings = {
  apiKey: process.env.VUE_APP_YANDEX_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
};
const emitter = mitt();
const app = createApp(App)
  .use(store)
  .use(IonicVue, { mode: 'ios' })
  .use(router)
  .use(Maska)
  .use(i18n)
  .use(YmapPlugin, settings)
  .use(
    createIntl({
      locale: 'ru',
      defaultLocale: 'ru',
      messages: ru
    })
  );

app.component('IonIcon', IonIcon);

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$toastSendError = () => {
  showToast.call(this, i18n.global.t('ERROR-SEND-DATA'));
};
app.config.globalProperties.$numeral = showNumber;
app.config.globalProperties.$getThumb = getThumbUrl;
app.config.globalProperties.$ROUTES = ROUTES;

app.config.performance = true;

Sentry.init({
  app,
  dsn: process.env?.VUE_SENTRY_DSN,
  integrations: [
    new CaptureConsole({
      levels: ['error']
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  tracesSampleRate: 1.0
});

window.$store = store;

router.isReady().then(() => {
  app.mount('#app');
});

export default app;
