import API from './index';

export const addTeacher = (sessionType, idSchool, data) =>
  API.post(`/${sessionType}/school/${idSchool}/teacher`, data);
export const editTeacher = (sessionType, idSchool, idTeacher, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/teacher/${idTeacher}?_method=PUT`,
    data
  );

export const getTeacher = (sessionType, idSchool, idTeacher) =>
  API.get(`/${sessionType}/school/${idSchool}/teacher/${idTeacher}`);
export const getTeachers = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/teachers`);

export const deleteTeacher = (sessionType, idSchool, idTeacher) =>
  API.delete(`/${sessionType}/school/${idSchool}/teacher/${idTeacher}`, {});

export const getTeacherStudents = (sessionType, idSchool, idTeacher) =>
  API.get(`/${sessionType}/school/${idSchool}/teacher/${idTeacher}/students`);

export const setTeacherStudents = (
  sessionType,
  idSchool,
  idTeacher,
  studentsIds
) =>
  API.put(`/${sessionType}/school/${idSchool}/teacher/${idTeacher}/students`, {
    students_ids: studentsIds
  });

export const setSheduleTeachers = (sessionType, idSchool, data) =>
  API.put(`/${sessionType}/school/${idSchool}/set_schedule_teachers`, {
    data
  });

export const getSheduleTeachers = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/get_schedule_teachers`, {});
