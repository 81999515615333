<template>
  <IonButton expand="block" v-bind="$attrs">
    <IonLabel>
      <span v-if="!loading">{{ label }}</span>
      <Spinner v-if="loading" />
    </IonLabel>
  </IonButton>
</template>

<script>
import { IonLabel, IonButton } from '@ionic/vue';
import Spinner from './Spinner.vue';

export default {
  name: 'Button',
  components: {
    IonLabel,
    IonButton,
    Spinner
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped></style>
