import { getNotifications } from '../../API/Notifications';

const getDefaultState = () => ({ rows: undefined, countNotReaded: 0 });

const mutations = {
  SET_NOTIFICATIONS: (state, { notifications }) => (state.rows = notifications),
  SET_COUNT_NOT_READED_NOTIFICATIONS: (state, count) =>
    (state.countNotReaded = count),
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadNotifications({ commit, state }, { isUpdate }) {
    if (typeof state.rows === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_NOTIFICATIONS', { notifications: [] });
      }
      try {
        const notifications = await getNotifications();
        commit('SET_NOTIFICATIONS', { notifications });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async loadCountNotReadedNotifications({ commit }) {
    try {
      const countNotReaded = await getNotifications({
        type: 'count_not_readed'
      });
      commit('SET_COUNT_NOT_READED_NOTIFICATIONS', countNotReaded);
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  notifications: (state) => state.rows || [],
  countNotReaded: (state) => state.countNotReaded
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
