import API from './index';

export const getFinance = (sessionType, idSchool, idFinance) =>
  API.get(`/${sessionType}/school/${idSchool}/finance/${idFinance}`);
export const getFinances = (
  sessionType,
  idSchool,
  dateFrom,
  dateTo,
  params = {}
) =>
  API.get(`/${sessionType}/school/${idSchool}/finances`, {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
      ...params
    }
  });
export const addFinance = (sessionType, idSchool, dataFinance) =>
  API.post(`/${sessionType}/school/${idSchool}/finance`, dataFinance);

export const deleteFinance = (sessionType, idSchool, idFinance) =>
  API.delete(`/${sessionType}/school/${idSchool}/finance/${idFinance}`);

export const updateFinance = (sessionType, idSchool, idFinance, dataFinance) =>
  API.put(
    `/${sessionType}/school/${idSchool}/finance/${idFinance}`,
    dataFinance
  );
