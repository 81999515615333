import { getTimetables, deleteTimetable } from '../../API/Timetables';

const getDefaultState = () => ({ rows: {} });

const mutations = {
  SET_TIMETABLES: (state, { timetables, key }) =>
    (state.rows[key] = timetables),
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadTimetables(
    { commit, rootState, state },
    { idSchool, isUpdate, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = type + idSchool;
    if (typeof state.rows[key] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_TIMETABLES', { timetables: [], key });
      }
      try {
        const timetables = await getTimetables(selfType, idSchool);
        commit('SET_TIMETABLES', { timetables, key });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteTimetable(
    { commit, rootState, getters },
    { idSchool, idTimetable, type }
  ) {
    const selfType = type ?? rootState.session.type;
    const key = selfType + idSchool;
    try {
      const res = await deleteTimetable(selfType, idSchool, idTimetable);
      if (res) {
        commit('SET_TIMETABLES', {
          timetables: getters
            .getTimetables(idSchool, selfType)
            .filter((f) => f.id !== idTimetable),
          key
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getTimetables: (state) => (idSchool, type) =>
    state.rows[type + idSchool] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
