import { API_KEY, APP_NAME, LOGGING_ENABLED } from '../config.js'
import { Tinode } from '../lib/tinode-sdk/tinode.js'

export function tnSetup(
  serverAddress,
  secureConnection,
  transport,
  locale,
  persistentCache,
  onSetupCompleted,
  platform = 'web',
  logging = true
) {
  const params = {
    appName: APP_NAME,
    host: serverAddress,
    apiKey: API_KEY,
    transport: transport,
    secure: secureConnection,
    platform,
    persist: false // выключен кэш,
  }
  console.log(params)
  const tinode = new Tinode(params, onSetupCompleted)
  tinode.setHumanLanguage(locale)
  tinode.enableLogging(true)
  tinode.getMeTopic()

  return tinode
}
