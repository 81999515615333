import API from './index';

export const addWorkTime = (sessionType, idSchool, idTeacher, data) =>
  API.post(
    idTeacher
      ? `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/work_time`
      : `/${sessionType}/school/${idSchool}/work_time`,
    data
  );
export const editWorkTime = (
  sessionType,
  idSchool,
  idTeacher,
  idWorkTime,
  data
) =>
  API.put(
    idTeacher
      ? `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/work_time/${idWorkTime}`
      : `/${sessionType}/school/${idSchool}/work_time/${idWorkTime}`,
    data
  );

export const getWorkTimes = (sessionType, idSchool, idTeacher, params = {}) =>
  API.get(
    idTeacher
      ? `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/work_times`
      : `/${sessionType}/school/${idSchool}/work_times`,
    {
      params
    }
  );

export const getWorkTime = (sessionType, idSchool, idTeacher, idWorkTime) =>
  API.get(
    idTeacher
      ? `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/work_time/${idWorkTime}`
      : `/${sessionType}/school/${idSchool}/work_time/${idWorkTime}`
  );

export const deleteWorkTime = (sessionType, idSchool, idTeacher, idWorkTime) =>
  API.delete(
    idTeacher
      ? `/${sessionType}/school/${idSchool}/teacher/${idTeacher}/work_time/${idWorkTime}`
      : `/${sessionType}/school/${idSchool}/work_time/${idWorkTime}`,
    {}
  );
