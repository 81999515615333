import * as ROUTES from '../router/routes';

export function parseUrl(url) {
  if (!/mymove\.ru/.test(url)) {
    return null;
  }
  const list = [
    {
      reg: /\/company\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterCompany().name,
          params: {
            school_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/category\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterPostsCategory().name,
          params: {
            category_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/video\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterVideo().name,
          params: {
            post_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/post\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routePostByArticle().name,
          params: {
            post_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/user\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeUserByPlatform().name,
          params: {
            user_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/me$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterProfile().name,
          params: {}
        };
      }
    },
    {
      reg: /\/cabinet$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterMyCompanies().name,
          params: {}
        };
      }
    },
    {
      reg: /\/posts$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterPosts().name,
          params: {}
        };
      }
    },
    {
      reg: /\/experts$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterExperts().name,
          params: {}
        };
      }
    },
    {
      reg: /\/companies$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterExperts().name,
          params: {}
        };
      }
    },
    {
      reg: /\/forum$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterQuestions().name,
          params: {}
        };
      }
    },
    {
      reg: /\/theme-forum\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterQuestionsTheme().name,
          params: {
            theme_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/category-forum\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterQuestionsCategory().name,
          params: {
            category_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/lectures$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterLessons().name,
          params: {}
        };
      }
    },
    {
      reg: /\/theme-lectures\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterLessonsTheme().name,
          params: {
            theme_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/category-lectures\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterLessonsCategory().name,
          params: {
            category_id: match[1]
          }
        };
      }
    }
  ];
  for (let i in list) {
    let row = list[i];
    if (row.reg.test(url)) {
      const match = url.match(row.reg);
      return {
        match,
        route: row.getRoute(match),
        ...row
      };
    }
  }
  return null;
}
