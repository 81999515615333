<template>
  <IonSelect
    :ok-text="$t('OK')"
    :cancel-text="$t('CANCEL')"
    :value="lang"
    interface="popover"
    @ionChange="change"
  >
    <IonSelectOption
      v-for="option in options"
      :key="option.name"
      :value="option.value"
      >{{ option.name }}
    </IonSelectOption>
  </IonSelect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { IonSelect, IonSelectOption } from '@ionic/vue';

export default {
  name: 'SelectLocale',
  components: {
    IonSelect,
    IonSelectOption
  },

  computed: {
    ...mapGetters(['lang']),
    options() {
      return [
        {
          name: this.$t('RU'),
          value: 'ru'
        },
        {
          name: this.$t('EN'),
          value: 'en'
        }
      ];
    }
  },
  methods: {
    ...mapActions(['setLang']),
    change(e) {
      this.setLang(e.detail.value);
    }
  }
};
</script>
