import API from './index';

export const addService = (sessionType, idSchool, data) =>
  API.post(`/${sessionType}/school/${idSchool}/service`, data);
export const editService = (sessionType, idSchool, idService, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/service/${idService}?_method=PUT`,
    data
  );

export const getServices = (sessionType, idSchool) =>
  API.get(`/${sessionType}/school/${idSchool}/services`);

export const getService = (sessionType, idSchool, idService) =>
  API.get(`/${sessionType}/school/${idSchool}/service/${idService}`);

export const deleteService = (sessionType, idSchool, idService) =>
  API.delete(`/${sessionType}/school/${idSchool}/service/${idService}`, {});

export const getServiceTeachers = (
  sessionType,
  idSchool,
  idService,
  params = {}
) =>
  API.get(`/${sessionType}/school/${idSchool}/service/${idService}/teachers`, {
    params
  });

export const setServiceTeachers = (
  sessionType,
  idSchool,
  idService,
  teachersIds
) =>
  API.put(`/${sessionType}/school/${idSchool}/service/${idService}/teachers`, {
    teachers_ids: teachersIds
  });

export const getServicesGroups = (idSchool) =>
  API.get(`/services_groups`, {
    params: {
      school_id: idSchool
    }
  });
