import API from './index';

export const addStudent = (sessionType, idSchool, dataStudent) =>
  API.post(`/${sessionType}/school/${idSchool}/student`, dataStudent);
export const editStudent = (sessionType, idSchool, idStudent, dataStudent) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}?_method=PUT`,
    dataStudent
  );
export const getStudents = (sessionType, idSchool, params = {}) =>
  API.get(`/${sessionType}/school/${idSchool}/students`, {
    params
  });
export const getStudent = (sessionType, idSchool, idStudent) =>
  API.get(`/${sessionType}/school/${idSchool}/student/${idStudent}`);
export const deleteStudent = (sessionType, idSchool, idStudent) =>
  API.delete(`/${sessionType}/school/${idSchool}/student/${idStudent}`);

export const getStudentTeachers = (sessionType, idSchool, idStudent) =>
  API.get(`/${sessionType}/school/${idSchool}/student/${idStudent}/teachers`);

export const setStudentTeachers = (
  sessionType,
  idSchool,
  idStudent,
  teachersIds
) =>
  API.put(`/${sessionType}/school/${idSchool}/student/${idStudent}/teachers`, {
    teachers_ids: teachersIds
  });

export const setStudentTimetables = (
  sessionType,
  idSchool,
  idStudent,
  timetableType,
  timetablesIds
) =>
  API.put(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/timetables`,
    {
      type: timetableType,
      timetables_ids: timetablesIds
    }
  );

export const getStudentsGroups = (idSchool) =>
  API.get(`/students_groups`, {
    params: {
      school_id: idSchool
    }
  });
