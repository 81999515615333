import { getWorkTimes, deleteWorkTime } from '../../API/WorkTime';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_WORK_TIMES(state, { workTimes, idTeacher }) {
    state.rows[idTeacher] = workTimes;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadWorkTimes(
    { commit, rootState, state },
    { idSchool, idTeacher, isUpdate, type }
  ) {
    if (typeof state.rows[idTeacher] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_WORK_TIMES', { workTimes: [], idTeacher });
      }
      try {
        const workTimes = await getWorkTimes(
          type ?? rootState.session.type,
          idSchool,
          idTeacher
        );
        commit('SET_WORK_TIMES', { workTimes, idTeacher });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteWorkTime(
    { commit, rootState, getters },
    { idSchool, idTeacher, idWorkTime, type }
  ) {
    try {
      const res = await deleteWorkTime(
        type ?? rootState.session.type,
        idSchool,
        idTeacher,
        idWorkTime
      );
      if (res) {
        commit('SET_WORK_TIMES', {
          workTimes: getters
            .getWorkTimes(idTeacher)
            .filter((l) => l.id !== idWorkTime),
          idTeacher
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getWorkTimes: (state) => (idTeacher) => state.rows[idTeacher] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
