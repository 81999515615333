<template>
  <div class="progress-upload-videos">
    <template v-for="video in list">
      <div
        v-if="!video.complete"
        :key="video.postId"
        class="video-block"
        :style="getStyleVideo(video)"
      >
        <CircleProgress
          :percent="video.progress * 100"
          :size="50"
          :border-width="4"
          :border-bg-width="4"
          :fill-color="'var(--ion-color-success)'"
        ></CircleProgress>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CircleProgress from '../ui/CircleProgress.vue';

export default {
  name: 'ProgressVideoUpload',
  components: {
    CircleProgress
  },
  computed: {
    ...mapGetters('videoPosts', ['list'])
  },
  methods: {
    getStyleVideo(video) {
      const styles = {};
      if (video?.cover) {
        styles['background-image'] = `url(${video?.cover})`;
      }
      return styles;
    }
  }
};
</script>
<style lang="scss" scoped>
.progress-upload-videos {
  position: fixed;
  bottom: 150px;
  left: 10px;
  .video-block {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50px;
  }
}
</style>
