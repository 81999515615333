import { getMainBanners } from '../../API/Posts';

const getDefaultState = () => ({ rows: null, loading: false });
const mutations = {
  SET_BANNERS(state, { banners }) {
    state.rows = banners;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadBanners({ commit, state }, { isUpdate }) {
    if (!state.loading) {
      if (state.rows === null || isUpdate) {
        state.loading = true;
        try {
          const banners = await getMainBanners();
          commit('SET_BANNERS', { banners });
        } catch (e) {
          console.error(e);
        } finally {
          state.loading = false;
        }
      }
    }
  }
};
const getters = {
  getBanners: (state) => state.rows ?? []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
