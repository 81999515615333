export function clearFields() {
  this.fields = { ...this.emptyFields };
  this.errorFields = {};
  this.error = '';
  return true;
}

export function objToFormData(obj) {
  const fd = new FormData();
  Object.keys(obj).forEach((key) => {
    let val = obj[key];
    if (typeof val === 'boolean') {
      val = val ? 1 : 0;
    }
    if (typeof val === 'undefined') {
      val = '';
    }
    fd.append(key, val === null ? '' : val);
  });

  return fd;
}

export function checkFields(e) {
  let hasErrors = false;
  if (typeof e === 'object' && e?.response?.data?.errors) {
    const { errors } = e.response.data;
    Object.keys(errors).forEach((i) => {
      this.errorFields[i] = errors[i].join(', ');
    });
  } else {
    Object.keys(this.fields).forEach((i) => {
      if (
        this.requiredFields.indexOf(i) >= 0 &&
        !this.fields[i] &&
        this.fields[i] !== 0
      ) {
        this.errorFields[i] = this.$t('REQUIRED-TO-FILL');
        hasErrors = true;
      } else {
        this.errorFields[i] = false;
      }
    });
  }
  return hasErrors;
}
