import numeral from 'numeral';

export const showNumber = (n) => {
  const r = numeral(n).format('0.0a');
  return /\.0.?$/.test(r) ? r.replace(/\.0(.?)$/, '$1') : r;
};

export const showHours = (val = '01:00') => {
  if (val) {
    const split = val?.split(':');

    const hours = parseInt(split[0]);
    const mins = parseInt(split[1]);
    return hours > 0 && mins > 0
      ? `${hours} ч ${mins} мин.`
      : hours > 0
      ? `${hours} ч`
      : `${mins} мин.`;
  } else {
    return val;
  }
};
