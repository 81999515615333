import API from './index';

export const addComment = (sessionType, idSchool, idStudent, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/comment`,
    data
  );
export const editComment = (
  sessionType,
  idSchool,
  idStudent,
  idComment,
  data
) =>
  API.put(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/comment/${idComment}`,
    data
  );
export const getComments = (sessionType, idSchool, idStudent) =>
  API.get(`/${sessionType}/school/${idSchool}/student/${idStudent}/comments`);
export const getComment = (sessionType, idSchool, idStudent, idComment) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/comment/${idComment}`,
    {}
  );
export const deleteComment = (sessionType, idSchool, idStudent, idComment) =>
  API.delete(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/comment/${idComment}`
  );
