import { getLessonsPlus, deleteLessonPlus } from '../../API/LessonPlus';

const getDefaultState = () => ({ rows: {} });
const mutations = {
  SET_LESSONS_PLUS(state, { lessonsPlus, idStudent }) {
    state.rows[idStudent] = lessonsPlus;
  },
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadLessonsPlus(
    { commit, rootState, state },
    { idSchool, idStudent, isUpdate, type }
  ) {
    if (typeof state.rows[idStudent] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_LESSONS_PLUS', { lessonsPlus: [], idStudent });
      }
      try {
        const lessonsPlus = await getLessonsPlus(
          type ?? rootState.session.type,
          idSchool,
          idStudent
        );
        commit('SET_LESSONS_PLUS', { lessonsPlus, idStudent });
      } catch (e) {
        console.error(e);
      }
    }
  },
  async deleteLessonPlus(
    { commit, rootState, getters },
    { idSchool, idStudent, idLessonPlus, type }
  ) {
    try {
      const res = await deleteLessonPlus(
        type ?? rootState.session.type,
        idSchool,
        idStudent,
        idLessonPlus
      );
      if (res) {
        commit('SET_LESSONS_PLUS', {
          lessonsPlus: getters
            .getLessonsPlus(idStudent)
            .filter((l) => l.id !== idLessonPlus),
          idStudent
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};
const getters = {
  getLessonsPlus: (state) => (idStudent) => state.rows[idStudent] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
