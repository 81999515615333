import API from './index';

export const setProfile = (data) => API.post('/profile', data);
export const getProfile = () => API.get('/profile', { params: {} });
export const getProfileStatistic = () =>
  API.get('/profile/statistic', { params: {} });
export const checkSmsCode = (type, checkPhoneId, code) =>
  API.get('/check_sms_code', { params: { id: checkPhoneId, code, type } });
export const checkPhone = (phone, type) =>
  API.get('/check_phone', { params: { phone, type } });
export const changeSessionType = (type) =>
  API.post('/change_session_type', { type });

export const getUser = (userId) =>
  API.get(`/public/user/${userId}`, { params: {} });

export const getUserStatistic = (userId) =>
  API.get(`/public/user/${userId}/statistic`, { params: {} });

export const deleteAccount = (params = {}) =>
  API.put('/profile/delete', params);
export const cancelDeleteAccount = (params = {}) =>
  API.put('/profile/cancel_delete', params);

export const setUserBlock = (typeSession, userId) =>
  API.post(`/${typeSession}/user/${userId}/block`, {});

export const logoutUser = (typeSession) =>
  API.post(`/${typeSession}/logout`, {});

export const setUserSubscription = (typeSession, userId) =>
  API.post(`/${typeSession}/user/${userId}/subscription`, {});
