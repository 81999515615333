<template>
  <Modal :show="show" @hide="$emit('hide')">
    <IonRow class="ion-margin-bottom">
      <IonCol size="12" style="color: var(--ion-color-danger)">
        {{ $t('DELETE-ACCOUNT-TEXT-INFO') }}
      </IonCol>
      <IonCol size="12">
        {{ $t('DELETE-ACCOUNT-TEXT-INFO2') }}
      </IonCol>
    </IonRow>

    <ItemInput :label="'Номер мобильного телефона'">
      <InputPhone v-model="fields.phone" />
    </ItemInput>

    <template #buttons>
      <Button
        :label="$t('DELETE')"
        :loading="submitting"
        :disabled="!isFilledPhone"
        @click="submit"
      />
    </template>
  </Modal>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue';
import { mapGetters, mapActions } from 'vuex';
import { checkFields, clearFields } from '../../helpers/form';
import { formatPhone } from '../../helpers/formatter';
import Modal from './Modal.vue';
import ItemInput from './items/ItemInput.vue';
import Button from './Button.vue';
import InputPhone from './inputs/InputPhone.vue';
import { deleteAccount } from '../../API/User';

export default {
  name: 'ModalDeleteAccount',
  components: {
    Modal,
    ItemInput,
    Button,
    InputPhone,
    IonRow,
    IonCol
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hide'],
  data: () => ({
    fields: {
      phone: null
    },
    requiredFields: ['phone'],
    errorFields: {},
    submitting: false,
    showConfirm: true
  }),
  computed: {
    ...mapGetters('user', ['session', 'user']),
    isFilledPhone() {
      const phone = this.fields.phone.replace(/[^0-9,.]/g, '');
      return phone.length === 11;
    }
  },
  watch: {},
  methods: {
    ...mapActions('user', ['getUser']),
    formatPhone,
    clearFields,
    checkFields,

    submit() {
      if (!this.checkFields()) {
        this.submitting = true;
        const phone = this.fields.phone.replace(/[^0-9,.]/g, '');
        const promiseSubmit = deleteAccount({ phone });
        promiseSubmit
          .then(() => {
            this.$emit('hide');
            this.$toast(this.$t('REQUEST-SUCCESS-SEND'));
            this.getUser();
            this.clearFields();
          })
          .catch((e) => {
            this.$toast(this.$t('ERROR-SEND-DATA'));
            this.checkFields(e);
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    }
  }
};
</script>
