import { getTimetablesTeacher } from '../../API/TimetablesTeacher';

const getDefaultState = () => ({ rows: {} });

const mutations = {
  SET_TIMETABLES: (state, { timetables, idTeacher }) =>
    (state.rows[idTeacher] = timetables),
  RESET_STATE: (state) => Object.assign(state, getDefaultState())
};
const actions = {
  async loadTimetables(
    { commit, rootState, state },
    { idSchool, isUpdate, idTeacher, type }
  ) {
    if (typeof state.rows[idTeacher] === 'undefined' || isUpdate) {
      if (!isUpdate) {
        commit('SET_TIMETABLES', { timetables: [], idTeacher });
      }
      try {
        const timetables = await getTimetablesTeacher(
          type ?? rootState.session.type,
          idSchool,
          idTeacher
        );
        commit('SET_TIMETABLES', { timetables, idTeacher });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
const getters = {
  getTimetables: (state) => (idTeacher) => state.rows[idTeacher] || []
};
const state = getDefaultState;
export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
