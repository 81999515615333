import API from './index';

export const writeCompany = (sessionType = 'user', idCompany) => {
  return API.post(`/${sessionType}/company/${idCompany}/chat`);
};

export const writeUser = (sessionType = 'user', idUser) => {
  return API.post(`/${sessionType}/user/${idUser}/chat`);
};

export const getChatCabinets = () => {
  return API.get(`/user/chat/cabinets`);
};

export const findUsersForCommunicateChat = (query, schoolId) => {
  return API.get(`/user/chat/find`, {
    params: {
      query,
      school_id: schoolId
    }
  });
};

export const writeStudentFromSchool = (
  sessionType = 'user',
  schoolId,
  studentId
) => {
  return API.post(
    `/${sessionType}/school/${schoolId}/student/${studentId}/chat`
  );
};

export const getStudentsChatsFromSchool = (
  sessionType = 'user',
  schoolId,
  studentIds
) => {
  return API.post(`/${sessionType}/school/${schoolId}/students_chats`, {
    students_ids: studentIds
  });
};
