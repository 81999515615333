import API from './index';

export const addTimetableStudent = (sessionType, idSchool, idStudent, data) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/timetable`,
    data
  );
export const editTimetableStudent = (
  sessionType,
  idSchool,
  idStudent,
  idTimetable,
  data
) =>
  API.post(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/timetable/${idTimetable}?_method=PUT`,
    data
  );
export const getTimetablesStudent = (sessionType, idSchool, idStudent) =>
  API.get(`/${sessionType}/school/${idSchool}/student/${idStudent}/timetables`);
export const getTimetableStudent = (
  sessionType,
  idSchool,
  idStudent,
  idTimetable
) =>
  API.get(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/timetable/${idTimetable}`
  );

export const deleteTimetableStudent = (
  sessionType,
  idSchool,
  idStudent,
  idTimetable
) =>
  API.delete(
    `/${sessionType}/school/${idSchool}/student/${idStudent}/timetable/${idTimetable}`,
    {}
  );
